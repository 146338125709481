<template>
  <input
    type="text"
    v-mask="mask"
    inputmode="numeric"
    class="date-picker__input"
    :class="{
        'date-picker__input--fill': val,
      }"
    v-model="val"
    @blur="selectDate"
    @focus="$emit('focus')"
    :placeholder="placeholder"
  />
</template>

<script>
import moment from 'moment'

export default {
  name: 'DatePickerComponent',
  props: {
    value: [String, Date],
    placeholder: {
      type: [String],
      default: 'дд.мм.гггг'
    }
  },
  data () {
    return {
      val: null
    }
  },
  computed: {
    mask () {
      const array = this.val ? this.val.split('') : []
      const d1 = /[0-3]/
      let d2 = /[0-9]/
      if (array[0] === '0') {
        d2 = /[1-9]/
      }
      if (array[0] === '3') {
        d2 = /[0-1]/
      }
      const m1 = /[0-1]/
      let m2 = /[0-9]/
      if (array[3] === '0') {
        m2 = /[1-9]/
      }
      if (array[3] === '1') {
        m2 = /[0-2]/
      }
      return [d1, d2, '.', m1, m2, '.', /\d/, /\d/, /\d/, /\d/]
    }
  },
  watch: {
    value () {
      this.val = this.value ? moment(this.value).format('DD.MM.YYYY') : null
    }
  },
  mounted () {
    this.val = this.value ? moment(this.value).format('DD.MM.YYYY') : null
  },
  methods: {
    selectDate () {
      if (this.val) this.$emit('input', moment(this.val, 'DD.MM.YYYY').format())
      else this.$emit('input', null)
      this.$emit('blur')
    }
  }
}
</script>

<style lang="scss">
@import "~@/styles/components/forms.scss";

.date-picker {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  &--drop &__input {
    border-radius: 12px 12px 0 0;
    border-color: rgba(0, 51, 102, 0.7);
  }

  &__input {
    @extend .form-group__input;
    margin-bottom: 0;
    min-width: 252px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .vc-title {
    text-transform: capitalize;
  }

  .vc-container {
    position: absolute;
    z-index: 1;
    transform: translateY(100%);
    bottom: 0;
    border-color: rgba(0, 51, 102, 0.7) !important;
    border-radius: 0 0 12px 12px !important;
    border-top: none !important;
  }
}
</style>
